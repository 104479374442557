.order-info-box {
    width: 90%;
}

.order-info-detail-title {
    font-size: 18px;
    font-weight: 600;
    padding: 20px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.order-info-detail-box {
    display: flex;
    margin-top: 30px;
}

.order-info-detail-left {
    width: 50%;
}

.order-info-detail-right {
    width: 50%;
}
.order-left-box {
    display: flex;
    margin: 10px 0;
}

.left-title {
    width: 150px;
}

.order-left-box-flex {
    display: flex;
    margin: 10px 0;
}

.order-items-box {
    margin-top: 20px;
}

.order-items-title {
    font-size: 18px;
    font-weight: 600;
}

.order-items {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.order-item-box {
    display: flex;
    margin: 10px 0;
    width: 100%;
}

.item-pic {
    width: 10%;
}

.item-name-area {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.item-price-area {
    width: 30%;
    text-align: right;
    font-weight: 600;
}

.item-promo-area {
    font-size: 14px;
    font-weight: 400;
    color: grey
}

.item-price-box {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.price-total {
    font-size: 16px;
    font-weight: 600;
}

.order-history-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.cart-list-option-title {
    font-size: 12px;
}

.cart-list-option-detail {
    font-size: 12px;
    color: #ff0000;
}

.loading-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    scroll-behavior: smooth;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 3px solid #f2f3f4;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.1, 0.1, 0.1, 0.1) infinite;
    border-color: #f2f3f4 transparent transparent transparent;
}

.search_bar_result {

    width: 100%;
    height: 300px;
    overflow: scroll;
    
}

.search_bar_list {
    display: flex;
    align-items: center;
    padding: 10px 0;
   
   
}

.search_bar_list_img {
    margin-right: 20px;
    
}

.search_bar_img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.search_bar_list_name {
    cursor: pointer;
}

.product-option-door-box {
    width: 100%;
}

.product-option-door-star {
    color: red;
}

.product-option-door-select {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px;
}

.product-option-door-select-error {
    border: 1px dashed red;
}

.product-option-door-item {
    width: 48%;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.product-option-door-selected {
    background: #edc400;
}

.product-option-door-icon {
    width: 80px;
    height: 80px;
}

.product-option-divider {
    padding: 20px 0;
}

.carts-promo-code {
    margin-top: 20px;
    padding: 0 20px;
    display: flex;
    align-items: center;
}
.carts-promo-code-left {
    width: 150px;
    text-align: right;
    margin-right: 10px;
}

.promo-code-box {
    display: flex;
}
.promo-code-button {
    border: none;
    background: #ffffff;
    cursor: pointer;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.lds-logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lds-logo-pic {
    width: 30px;
    height: 30px;
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    /* background: grey; */
    margin-bottom: 30px;
}

.color-icon {
    width: 30px;
    height: 30px;
    border: 1px solid #909090;
    border-radius: 50%;
}

.color-icon-big {
    width: 50px;
    height: 50px;
    border: 1px solid #909090;
    border-radius: 5px;
}

.color-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.color-box-d {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.product-color-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-color-box {
    display: flex;
    flex-direction: column;
}

.product-color-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.picture-point-area {
    position: relative;
}

.picture-point {
    width: 24px;
    height: 24px;
    background: rgba(66, 65, 63, 0.6);
    position: absolute;
    border: 1px solid #85796d;
    border-radius: 50%;
    z-index: 1;
}

.picture-point::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%) translateX(-50%);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: #edc400;
    z-index: 1;
}

.picture-point-detail {
    position: absolute;
    width: 160px;
    height: auto;
    background: rgba(221, 221, 221, 0.9);
    z-index: 9999;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.picture-point-detail-left {
    left: -170px;
}

.picture-point-detail-right {
    left: 30px;
}

.picture-point-name {
    position: absolute;
    left: 10px;
}

.div-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pickup-title {
    font-size: 18px;
    font-weight: 600;
}

.pickup-detail {
    margin: 20px 0;
}

.pickup-list-box {
    width: 100%;
    display: flex;
    padding: 10px 0;
}

.pickup-list-radio {
    width: 50px;
}

.pickup-address-box {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
}

.pickup-box {
    width: 100%;
    padding: 20px;
}

.product-type-box {
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
}

.product-type-container {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
}

.product-type-box-selected {
    background-color: #edc400;
}

@media screen and (max-width: 768px) {
    .product-type-container {
        display: flex;
        flex-direction: column;
        margin: 20px;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .product-type-box {
        width: 120px;

        margin-right: 0px;
        cursor: pointer;
        padding: 5px;
        margin-bottom: 10px;
    }
}
